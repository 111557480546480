.calendarAgendaCont {
  overflow-y: scroll;
  position: relative;
}
.noActivitieMsg {
  font-size: 26px;
  color: #00264d;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  text-align: center;
}
.calendarAgendaHeader {
  justify-content: space-between;
  height: 40px;
  padding: 0 20px;
}
@media (min-width: 768px) {
  .calendarAgendaHeader {
    height: 100px;
  }
  .viewerCal .calendarAgendaHeader {
    height: 50px;
  }
}
.agenda__cell {
  border-bottom: none;
}
.agenda__table > table {
  overflow: hidden;
}
.horaInicio,
.disable-select {
  border-top: 1px solid rgba(192, 192, 192, 0.4);
}
.agendaIcon {
  display: inline-block;
  margin: 0;
  width: 15px;
  height: 15px;
  background-image: url(../../img/agenda.avif);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (min-width: 768px) {
  .agendaIcon {
    width: 20px;
    height: 20px;
  }
}
.color-1,
.color-2,
.color-3,
.color-4,
.color-5,
.color-6,
.color-7,
.color-8,
.color-9 {
  border-bottom: none !important;
}
.handler {
  cursor: ns-resize !important;
}
.drag-handle-icon {
  display: none !important;
}
.transparentHandler {
  /*display: block;*/
  opacity: 0;
}
.hideHandler {
  display: none;
}
.DroppableProvided .placeholder {
  display: none;
}
.agenda__table.--header .agenda__cell {
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  margin: 5px;
  height: 30px !important;
}
@media (min-width: 768px) {
  .agenda__table.--header .agenda__cell {
    height: 45px !important;
  }
}
.control-buttons {
  display: flex;
}
.agenda__cell.--controls,
.agenda__cell.--time,
.agenda__cell.--time-now {
  width: 50px !important;
  max-width: 50px !important;
}
.agenda__cell.--controls {
  border-radius: 5px;
  flex-grow: 0;
}
.agenda__cell.--controls:hover {
  background-color: var(--agendaPrincipal);
}
.dayCalendarHeader {
  text-transform: capitalize;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  border: 5px solid #fff;
  color: #00264d;
  transition: background 0.3s ease-in-out;
}
.dayCalendarHeader span {
  font-weight: 400;
  color: #7e96ae;
}
.dayCalendarHeaderSelected,
.dayCalendarHeader:hover {
  height: 100%;
  background: #f2f5f8;
  border-radius: 6px;
  border: 5px solid #fff;
}
.addHotelCalBtn {
  position: absolute;
  text-transform: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  background-color: #fff;
  inset: 0;
  padding: 0 10px;
  transition: all 0.5s ease-in;
  overflow: hidden;
  color: #000;
  font-weight: 700;
  line-height: 1.1;
  top: 100%;
  z-index: 4;
}
.addHotelCalBtn button {
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  background-color: #ff5757;
  border: none;
  height: 40px;
  width: 40px;
}
.agenda__cell:hover .addHotelCalBtn,
.dayCalendarHeaderShow .addHotelCalBtn {
  top: 0;
}
.control-buttons > button {
  background-color: var(--agendaSecundario);
  color: #fff;
  font-weight: 600;
  border-radius: 1rem;
  padding: 0.5rem 1rem;
  border: 1px solid #fff;
}
.button-control:hover {
  cursor: pointer;
}
.agenda__table.--body {
  height: calc(100vh - 179px) !important;
  scroll-behavior: smooth;
  overflow-x: hidden;
}
.viewerCal .agenda__table.--body {
  height: calc(100vh - 128px) !important;
}
.agenda__table.--header {
  border-top: 1px solid #dee3ea;
  border-bottom: 1px solid #dee3ea;
}
@media (min-width: 768px) {
  .agenda__table.--body {
    height: calc(100vh - 220px) !important;
  }
  .iframe-calendar .agenda__table.--body {
    height: calc(100vh - 150px) !important;
  }
  .viewerCal .agenda__table.--body {
    height: calc(100vh - 170px) !important;
  }

  .agenda__cell_selected {
    background-color: transparent !important;
  }
}
.rangeDate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 700;
  font-size: 15px;
  text-align: end;
}
.daySelectorInput,
.daySelectorPencilInput {
  position: relative;
  height: 15px;
  width: 15px;
}
.daySelectorInput > input,
.daySelectorPencilInput > input {
  flex-direction: row-reverse;
  border: none;
  color: transparent;
  background-color: transparent;
  opacity: 1;
  position: relative;
  height: 15px;
  width: 15px;
}
.daySelectorInput > input:focus,
.daySelectorPencilInput > input:focus {
  opacity: 0;
}
.daySelectorInput > input::-webkit-calendar-picker-indicator,
.daySelectorPencilInput > input::-webkit-calendar-picker-indicator {
  display: block;
  z-index: 20;
  background: transparent;
  color: transparent;
  cursor: pointer;
  height: 20px;
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: -10px;
  width: 20px;
}
.daySelectorInput::after {
  content: "";
  display: block;
  position: absolute;
  border: 3px solid #212529;
  border-top: none;
  border-left: none;
  transform: rotate(45deg);
  z-index: -1;
  top: 3px;
  left: 3px;
  width: 10px;
  height: 10px;
}
.daySelectorPencilInpu {
  background-color: var(--agendaBtnPrincipal);
}
.daySelectorPencilInput > input {
  flex-direction: row-reverse;
  border: none;
  color: transparent;
  background-color: transparent;
  opacity: 1;
  position: absolute;
  top: 0;
  right: 0;
  height: 15px;
  width: 15px;
}
.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  color: #fff;
  text-align: center;
  font-weight: 600;
  top: 5px;
  width: 22px;
  height: 22px;
  border: 1.5px solid #fff;
  box-sizing: border-box;
  box-shadow: 0 2px 9px rgba(138, 142, 146, 0.3);
  font-size: 8px;
  border-radius: 100%;
}
@media (min-width: 576px) {
  .circle {
    font-size: 12px;
    width: 25px;
    height: 25px;
  }
}
@media (min-width: 768px) {
  .circle {
    top: 0;
    font-size: 14px;
    width: 30px;
    height: 30px;
  }
  .rangeDate {
    font-size: 18px;
  }
}
.blueCircle {
  background-color: #106ad4;
  color: #fff;
}
.circleadd {
  background-color: #f2f5f8;
  color: #106ad4;
  border: 1px dashed #106ad4;
  font-size: 10px;
}
.circlePersons {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 600;
  top: 0;
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  box-shadow: 0 2px 9px rgba(138, 142, 146, 0.3);
  font-size: 14px;
  border-radius: 100%;
  background-color: #f2f5f8;
  color: #106ad4;
  border: 1px dashed #106ad4;
  font-size: 8px;
  cursor: pointer;
}
.circleadd:hover {
  cursor: pointer;
}
.btnArrowDate {
  background-color: #fff;
  border: none;
  flex: 1 1 50%;
  transition: all 0.3s linear;
  padding: 0;
}
.btnArrowDate:hover {
  background-color: var(--agendaPrincipal);
}
.btnHeader {
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 6px;
  background-color: transparent;
  color: var(--agendaBtnPrincipal);
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
@media (min-width: 768px) {
  .btnHeader {
    background-color: #f2f5f8;
    margin-left: 5px;
    margin-top: 5px;
    width: 35px;
    height: 35px;
  }
}
.btnActHeaderSec,
.btnActmyPlces,
.btnActHeader {
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  font-weight: 600;
  height: 80%;
  max-width: 169px;
  border: none;
  border-radius: 6px;
}
.w-30 {
  width: 30%;
}
@media (min-width: 768px) {
  .btnActHeaderSec,
  .btnActmyPlces,
  .btnActHeader {
    height: 38px;
  }
}
@media only screen and (max-width: 1050px) {
  .btnActHeaderSec span,
  .btnActHeader span {
    display: none;
  }
}
.btnHeaderDisabled,
.btnActHeaderSec:disabled,
.btnActmyPlces:disabled,
.circleadd:disabled,
.btnHeader:disabled,
.circlePersons:disabled,
.btnActHeader:disabled {
  cursor: no-drop;
  opacity: 0.5;
}
.btnActHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1px 6px;
  min-width: 38px;
  background-color: #f2f5f8;
  text-overflow: ellipsis;
  color: #106ad4;
  border-radius: 6px;
}
.borderblue {
  border: 1px solid #106ad4;
}
.btnActHeader:hover {
  cursor: pointer;
}
.btnActHeaderSec {
  background-color: var(--agendaBtnPrincipal);
  color: #fff;
}
.btnActmyPlces {
  color: #fff;
  background: #ff5757;
  border-radius: 6px;
}
.btnOneClick {
  background: #ff5a5f;
  box-shadow: 2px 5px 20px 1px rgba(16, 106, 212, 0.2);
  border-radius: 6px;
}
@media (min-width: 768px) {
  .btnActHeaderSec {
    box-shadow: 2px 5px 20px 1px rgba(16, 106, 212, 0.2);
  }
  .btnActmyPlces {
    box-shadow: 2px 5px 20px 1px rgba(16, 106, 212, 0.2);
  }
}
.contentcircles {
  width: 120px;
  height: 30px;
  display: flex;
  position: relative;
}
.dropdownCreate .dropdown-menu.show {
  max-width: none;
  color: #000;
  text-align: center;
  background-color: transparent;
  padding: 0;
}
.cancelBtn {
  z-index: 2;
  position: absolute;
  margin: 0 !important;
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  top: -5px;
  right: -5px;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 1px solid #9b9a9a;
  box-shadow: 3px 5px 20px -3px #d3e0ec;
  border-radius: 5px;
}
.dropdownCreate .btn-group {
  width: 80%;
}
.calendarandMapCnt {
  display: flex;
}
.closeMapCalBtn {
  position: absolute;
  z-index: 1;
  border: none;
  background-color: #53585e75;
  color: #fff;
  border-radius: 6px;
  padding: 5px 10px;
  font-size: 12px;
  top: 15px;
  left: 15px;
  opacity: 0.7;
  transition: all 0.3s linear;
}
.closeMapCalBtn:hover {
  opacity: 1;
}
.forceWidth {
  width: 150%;
}
@media (max-width: 768px) {
  .forceWidth {
    width: 100%;
    max-width: 100% !important;
  }
}
.mobilePopUp {
  position: fixed;
  background-color: #fff;
  z-index: 10;
  padding-bottom: 60px;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  animation: slideInUp;
  animation-duration: 0.3s;
}
.closemobilepop {
  height: 25px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.titleMobileDays {
  position: fixed;
  width: max-content;
  z-index: 10;
  top: 18px;
  left: 65px;
}
.btnFooterCalendar {
  height: 40px;
  margin-top: 10px;
  width: 100%;
}
@media (min-width: 768px) {
  .btnFooterCalendar {
    display: none;
  }
}
/* tutorial light */
.CalendarHighlighted {
  z-index: 650;
  background: white;
  overflow: scroll;
  border-radius: 30px;
}
/*google calendar*/

.LoginGoogleBtn {
  width: 100%;
  overflow: scroll;
  font-size: 12px;
  font-weight: 600;
  height: 80%;
  max-width: 169px;
  border: none;
  border-radius: 6px;
  color: white;
  background: #ff5757;
  border: 1px solid #ff5757;
}
.subtitleGoogleBtn {
  text-align: center;
  margin: 0;
  color: #00264d;
  margin-bottom: 0.5rem;
  line-height: 1.2;
  font-size: 16px;
  font-weight: 400;
}
