.searchMobilePopUp {
  z-index: 500;
  padding: 20px 15px;
  padding-bottom: 60px;
  background-color: #fff;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  animation: slideInUp;
  animation-duration: .3s
}

@media (max-height: 600px) {
  .searchMobilePopUp {
    padding-bottom: 15px
  }
}

.noItemsMsg {
  text-align: center;
  color: #00264D;
  font-size: 16px;
  font-weight: 500
}

.searchinput {
  border-radius: 20px;
  border: none;
  padding: 10px;
  width: 100%;
  height: 40px
}

.searchinput::placeholder {
  color: #7e96ae;
  font-weight: 300;
  font-size: 14px
}