.navegation {
    margin-left: 30px;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-evenly
}
.navegation>div {
    display: flex;
    align-items: center
}
.navegation>div>* {
    cursor: pointer;
    color: #7E96AE;
    text-decoration: none;
    transition: all .5s ease-in-out;
    margin: 0 10px;
    width: auto;
    text-align: center
}
.navegation>div>:hover,
.selectedPage {
    text-decoration: none;
    color: #26292E !important
}
.navegation>div>:hover svg,
.selectedPage svg {
    text-decoration: none;
    color: var(--agendaBtnPrincipal) !important
}
.navegationMobile {
    margin-top: 40px;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    align-items: flex-start
}
.navegationMobile>div {
    flex: 0 0 100%;
    display: flex;
    align-items: center
}
.navegationMobile>a {
    flex: 0 0 100%;
    display: flex;
    font-size: 18px;
    font-weight: 600;
    text-decoration: none !important;
    justify-content: center;
    align-items: center
}
.navegationMobile>div>* {
    cursor: pointer;
    color: #7E96AE;
    text-decoration: none;
    transition: color .5s ease-in-out;
    margin: 0 10px;
    width: 100%;
    text-align: end
}
.navegationMobile>div>:hover {
    text-decoration: none;
    color: var(--agendaBtnPrincipal)
}