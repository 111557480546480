.searchCont {
  border-radius: 8px;
  height: 60px;
  box-shadow: 2px 7px 35px 4px rgba(16, 106, 212, 0.1);
}
.searchCont > * {
  height: 100%;
}
.inputCont {
  position: relative;
  z-index: 2;
  width: 57vw;
  height: 50px;
  min-width: 410px;
  display: flex;
  flex-wrap: wrap;
}
@media only screen and (max-width: 768px) {
  .inputCont {
    min-width: auto;
    width: 100%;
  }
  .searchCont {
    height: 40px;
  }
}
.inputCont > * {
  border: none;
  background-color: white;
}
.inputCity {
  border: none;
  width: 100%;
  height: 100%;
  padding-left: 10px;
  text-transform: capitalize;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 137% */

  display: flex;
  align-items: center;

  color: #26292e;
}
.inputCity::placeholder {
  text-transform: none;
}
.inputDate {
  border: none;
  width: 100%;
}
.inputCity,
.inputDate {
  font-size: 10px;
}

.btnCreateCalendar {
  font-size: 14px;
  margin: 8px;
  overflow: scroll;
  border: none;
  width: calc(100% - 16px);
  max-width: 150px;
  height: calc(100% - 16px);
  color: #fff;
  background-color: var(--agendaBtnPrincipal);
  transition: background 0.5s linear;
  border-radius: 8px;

  font-weight: 600;
  font-size: 10px;
  line-height: 20px;
  /* identical to box height, or 133% */
  align-items: center;
  text-align: center;
}
.btnExploreMobile {
  font-size: 14px;
  margin: 8px;
  overflow: scroll;
  border: none;
  width: calc(100% - 16px);
  max-width: 1500px;
  height: calc(100% - 16px);
  max-height: 53px;
  color: #fff;
  background-color: var(--agendaBtnPrincipal);
  transition: background 0.5s linear;
  border-radius: 8px;

  font-weight: 600;
  font-size: 10px;
  line-height: 20px;
  /* identical to box height, or 133% */
  align-items: center;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
}
.white-box {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: white;
}
@media (min-width: 576px) {
  .inputCity,
  .inputDate {
    font-size: 15px;
  }
  .btnCreateCalendar {
    font-size: 12px;
  }
}

/* Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
  .btnCreateCalendar {
    font-size: 15px;
  }
}

/*Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1300px) {
  .btnCreateCalendar {
    font-size: 18px;
  }
}
.btnCreateCalendar:hover {
  cursor: pointer;
  background-color: rgb(31, 92, 161);
}
.btnCreateCalendar:disabled {
  cursor: not-allowed;
  /*opacity: 0.5;*/
}
.optionsSearchCollapse {
  position: absolute;
  top: 45px;
  left: 0px;
  right: 0px;
}
.optionsSearch {
  background-color: white;
  border-radius: 10px;
  box-shadow: 2px 3px 25px -3px rgba(0, 38, 77, 0.2);
  list-style: none;
  padding: 5px 15px;
  position: absolute;
  max-height: 100px;
  width: 100%;
  overflow-y: scroll;
  flex: 0 0 80%;
}
/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  .optionsSearchCollapse {
    top: 65px;
  }
}
.optionsSearch > h2 {
  margin: 0;
  font-size: 20px;
  padding-top: 10px;
  position: sticky;
  background-color: white;
  top: 0;
  color: #343f4b;
  margin-bottom: 10px;
}
.optionsSearch > li {
  padding-top: 5px;
  color: #343f4b;
}
.optionsSearch > li:hover {
  cursor: pointer;
  background-color: #8c8c8c3f;
}
@media only screen and (max-width: 768px) {
  .optionsSearch > h2 {
    margin: 0;
    font-size: 16px;
    padding-top: 10px;
    position: sticky;
    background-color: white;
    top: 0;
    color: #343f4b;
  }
  .optionsSearch > li {
    font-size: 14px;
  }
  .optionsSearch {
    padding: 5px 10px;
  }
}
.noOptionAutoComplete {
  padding: 7px 14px;
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;
  cursor: auto !important;
  border-radius: 5px;
  color: #00264d;
  transition: all 0.3s ease-in-out;
}
.noOptionAutoComplete:hover {
  background-color: white !important;
  color: #00264d;
}
.createZeroCalendar {
  background-color: var(--agendaBtnPrincipal);
  color: white;
  border-radius: 8px;
  border: none;
  font-size: 12px;
  padding: 3px 10px;
  transition: all 0.3s linear;
}
.createZeroCalendar:hover {
  background-color: var(--agendaBtnPrincipalHover);
}
