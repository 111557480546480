.TrackingCookies {
    display: flex;
    justify-content: space-around;
    border-radius: 8px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    width: 100vw;
    padding: 15px;
    background-color: white;
}
.TrackingCookies button {
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 600;
    background-color: var(--agendaBtnPrincipal);
    transition: all .3s linear;
}
.TrackingCookies button:hover {
    transform: scale(1.1);
}
.popUpPreventOut{
    background-color: white;
    padding: 1.25em;
    
    border-radius: 5px;
}
/* medias querys
@media (min-width: 576px) { 
    .searchBodyCont {
    
    }
}

/* Medium devices (tablets, 768px and up)/
@media (min-width: 768px) { 
    .searchBodyCont {
    
    }
}

/* Large devices (desktops, 992px and up)/
@media (min-width: 992px) { 
    .searchBodyCont {
    
    }
}

/*Extra large devices (large desktops, 1200px and up)/
@media (min-width: 1200px) { 
    .searchBodyCont {
    
    }
    }
*/

            .SelectedWeekExample .DayPicker-Month {
              border-collapse: separate;
            }
            .SelectedWeekExample .DayPicker-WeekNumber {
              outline: none;
            }
            .SelectedWeekExample .DayPicker-Day {
              outline: none;
              border: 1px solid transparent;
            }
            .SelectedWeekExample .DayPicker-Day--hoverRange {
              background-color: #EFEFEF !important;
            }

            .SelectedWeekExample .DayPicker-Day--selectedRange {
              background-color: #4a91e298 ;
              border-top-color: #4A90E2;
              border-bottom-color: #4A90E2;
              border-left-color: #4a91e298;
              border-right-color: #4a91e298;
              border-radius: 100%;
            }

            .SelectedWeekExample .DayPicker-Day--selectedRangeStart {
              background-color: #4A90E2 ;
              border-left: 1px solid #4A90E2;
            }

            .SelectedWeekExample .DayPicker-Day--selectedRangeEnd {
              background-color: #4A90E2 !important;
              border-right: 1px solid #4A90E2;
            }

            .SelectedWeekExample .DayPicker-Day--selectedRange:not(.DayPicker-Day--outside).DayPicker-Day--selected,
            .SelectedWeekExample .DayPicker-Day--hoverRange:not(.DayPicker-Day--outside).DayPicker-Day--selected {
              /*border-radius: 0 !important;*/
              color: black !important;
            }
            .SelectedWeekExample .DayPicker-Day--hoverRange:hover {
              border-radius: 0 !important;
            }
          