@import './utils/react-datetime.css';
@import './styles/appbars.css';
@import './components/Agenda/styles.css';


html {
  /*font-size: 62.5%; /**es una transformacion necesaria para poder usar rem que mejoran la pag 1rem igual a 10px**/
  box-sizing: border-box;
  height: 100vh;
  /*overflow: scroll;*/
  /*font-family: sans-serif;*/
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(34, 42, 66, 0); 

  
  /*ocultar scroll mozilla y ie*/
  /*-ms-overflow-style: none;  IE 11 */
    scrollbar-width: 0px; /* Firefox 64 */
  
}
button {
  overflow: hidden!important;
}
*, *:before, *:after {
  box-sizing: inherit;
}
body {
  margin: 0;
  font-family: 'Poppins', sans-serif!important;
}
.scroll-behavior-smooth{
  
  scroll-behavior:smooth!important;
}

a {
  text-decoration: none;
  color: #47525E
}
a:visited {
  color: #2e363d;
}
a:Active {
  color: #2e363d;
}

.wrapper *{
  font-size: 12px!important;
}
.wrapper .iconFont *{
  font-size: 24px!important;
}
button:focus{outline:none !important;}
textarea:focus{outline:none !important;}
/*
h1 {
  font-size: 48px;
}
h2{
  font-size: 36px;
}
h3{
  font-size: 30px;
}h4{
  font-size: 24px;
}
h5{
  font-size: 24px;
}*/
h1  h2,h3,h4,h5,h6 {
  font-family: 'Poppins', sans-serif;
}
p{
  font-family: 'Poppins', sans-serif;}
button {
  font-size: 18px;
  overflow: hidden;
}
.adminCont p, 
.adminCont a, 
.adminCont button {
  font-family: "Poppins", sans-serif;
}
.setTransition {
  transition: all .3s linear;
}