.popUpCitiesCont {
  background-color: white;
  min-width: 300px !important;
  z-index: 5000;
  background-color: white;
  width: 100vw;
  height: 100vh;
  padding: 30px;
  padding-bottom: 75px;
  box-shadow: 2px 7px 30px 5px rgba(0, 38, 77, 0.2);
  border-radius: 9px;
  animation: slideInUp; 
  animation-duration: 0.3s; 
}
@media (min-width: 768px) {
  .popUpCitiesCont {
    width: 100%;
    
  padding-bottom: 30px;
    height: calc(90vh - 70px);
    max-width: 520px;
    animation: zoomIn; 
    animation-duration: 0.3s; 
  }
}
/***************header******************************************************************/
.headerCitycont{
  height: 100px;
}
.headerCitycont h2{
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  
  color: #00264D;
}
.SearchCitiesCont{
  border: 1px solid rgba(0, 0, 0, 0.5);
  box-shadow: none;
  
  backdrop-filter: blur(4px);
}
.searchbuttoncities{
  
  width: 25px!important;
  height: 25px!important;
}
/***************pody******************************************************************/
.bodyCitycont{
  height: calc(100% - 165px);
  z-index: -1;
  position: relative;
}
.bodyCitycont .infinite-scroll-component__outerdiv {
  height: 100%;
}
/***************footer******************************************************************/
.footerCitycont{
  height: 65px;
  border-top: 0.5px solid #DEE3EA;
  margin-top: 10px;
}
.footerCitycont>div{
  padding-top: 15px;
}
.footerCitycont p {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  
  color: #106AD4;
  transition: color .3s linear;
}
.footerCitycont p:hover {
  color: #FF5A5F;
}

.btnCreateActi{
  border: none;
  width: 100%;
  min-height: 34px;
  background: #106AD4;
  box-shadow: 2px 5px 20px 1px rgba(16, 106, 212, 0.2);
  border-radius: 6px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #FFFFFF;
}
.btnCloseCities{
  
  position: absolute;
  font-size: 15px;
  width: 30px;
  top: 15px;
  right: 15px;
  height: 30px;
  z-index: 0;
}
::-webkit-scrollbar{
  width: 3px !important;
}