.loadingCurtainCont {
    z-index: 1;
    inset: 0;
    top: 55px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center
}

@media (min-width: 768px) {
    .loadingCurtainCont {
        top: 70px
    }
}

.loader-Cont {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 120px
}

.loader-Cont .loader {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 50%;
    left: 50%;
    border: solid 8px transparent;
    border-top-color: #FF5757;
    border-left-color: #FF5757;
    border-radius: 100%;
    animation: loader 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) infinite;
    -o-animation: loader 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) infinite;
    -moz-animation: loader 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) infinite;
    -webkit-animation: loader 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) infinite
}

.loader-Cont .loader2 {
    height: 80%;
    width: 80%;
    border-top-color: #7E96AE;
    border-left-color: #7E96AE;
    animation: loader2 2s linear infinite;
    -o-animation: loader2 2s linear infinite;
    -moz-animation: loader2 2s linear infinite;
    -webkit-animation: loader2 2s linear infinite
}

@keyframes loader {
    0% {
        transform: translate(-50%, -50%) rotate(0deg)
    }

    100% {
        transform: translate(-50%, -50%) rotate(360deg)
    }
}

@keyframes loader2 {
    0% {
        transform: translate(-50%, -50%) rotate(0deg)
    }

    30% {
        transform: translate(-50%, -50%) rotate(160deg)
    }

    60% {
        transform: translate(-50%, -50%) rotate(-360deg)
    }

    100% {
        transform: translate(-50%, -50%) rotate(0deg)
    }
}

.loader-Logo {
    height: 50%;
    width: 50%;
    background-color: #FF5757;
    border-radius: 100%
}

.loader-Logo img {
    box-sizing: border-box;
    padding: 15px;
    height: 100%;
    width: 100%
}