.headerSearchCalCont {
  height: 95px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center
}

.activiteSearch {
  transform: translateY(10px);
  z-index: 1
}

@media only screen and (min-width: 375px) {
  .headerSearchCalCont {}
}

@media only screen and (min-width: 768px) {
  .headerSearchCalCont {
    height: 70px
  }

  .activiteSearch {
    transform: translateY(0px)
  }
}

.titleSearchShop {
  margin: 0;
  font-size: 22px;
  margin-top: 10px
}

.calendarAddDropdown {
  right: auto !important
}

@media only screen and (min-width: 1500px) {
  .titleSearchShop {
    font-size: 28px
  }
}

@media only screen and (max-width: 865px) {
  .titleSearchShop {
    font-size: 18px
  }
}

.searchbutton {
  position: absolute;
  background-color: var(--agendaBtnPrincipal);

  border: none;
  color: #fff;
  top: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  font-size: 9px;
  transition: background-color .3s linear
}

.searchbutton:hover {
  background-color: var(--agendaBtnPrincipalHover)
}

.searchinput {
  border-radius: 20px;
  border: none;
  width: 100%;
  padding-left: 18px;
  font-size: 10px;
  height: 30px
}


@media (min-width: 576px) {
  .searchbutton {
    width: 30px;
    height: 30px;
    font-size: 15px
  }

  .searchinput {
    height: 40px
  }
}


@media (min-width: 768px) {
  .searchinput {}
}

.saludo {
  font-size: 14px;
  font-weight: 400;
  align-items: center;
  color: #00264d
}

.searchinput::placeholder {
  color: #7e96ae;
  text-align: left;
  font-weight: 300;
  font-size: 12px
}


.countAct {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -5px;
  right: -5px;
  border-radius: 100%;
  background-color: red;
  border: 1px solid #fff;
  color: #fff;
  font-size: 10px;
  font-weight: 600;
  height: 15px;
  width: 15px
}


@media (min-width: 992px) {
  .countAct {
    height: 16px;
    width: 16px;
    font-size: 12px
  }
}


@media (min-width: 1300px) {
  .countAct {
    height: 20px;
    width: 20px;
    font-size: 14px
  }
}

.btncontCalendarCreate .btn-group {
  width: 100%
}

.btnList,
.btnCreateCal {
  height: 38px;
  max-width: 169px;
  width: 100%;
  font-size: 13px;
  border-radius: 6px;
  padding: 3px 5px;
  font-weight: 600;
  border: none;
  transition: opacity .3s linear
}

.btnCreateCal {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f2f5f8;
  background-color: #106ad4;
  border: 1px solid #106ad4
}

.btnCreateCal:disabled {
  opacity: .7
}

.btnList {
  background-color: #f2f5f8;
  color: #106ad4;
  border: 1px solid #106ad4
}

.btnList:hover,
.btnCreateCal:hover {
  opacity: 1.1
}

.btnList {
  margin-right: 10px
}

@media only screen and (min-width: 1500px) {

  .btnList,
  .btnCreateCal {
    font-size: 14px
  }
}

@media only screen and (max-width: 865px) {

  .btnList,
  .btnCreateCal {
    font-size: 10px
  }
}

.infoCosto>span {
  font-weight: 400;
  font-size: 1.5rem
}

.titleSlideCont {
  position: relative;
  text-overflow: ellipsis;
  display: flex;
  min-width: 5rem;
  width: 100%;
  height: 100px;
  margin-top: 15px;
  justify-content: space-between
}

.autoCompletOptions {
  position: absolute;
  overflow-y: scroll;
  color: #000;
  z-index: 2;
  top: 45px;
  right: 0;
  left: 0;
  background: #fff;
  box-shadow: 2px 3px 25px -3px rgba(0, 38, 77, 0.2);
  border-radius: 7px
}


.widthBtn {
  min-width: 169px
}

.pulseAnimation {
  box-shadow: 0 0 0 0 rgba(255, 87, 87, 1);
  transform: scale(1);
  animation: pulse-black 2s infinite
}

@keyframes pulse-black {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 87, 87, 0.7)
  }

  70% {
    transform: scale(0.95);
    box-shadow: 0 0 0 10px rgba(255, 87, 87, 0)
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 87, 87, 0)
  }
}