

.popUpBodyCont,
.totalPopUpCont,
.totalPopUpUpperCont  {
  z-index: 510;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 13%;
  left: 0;
  width: 100%;
  /*left: 40%;*/
}
.totalPopUpCont,
.totalPopUpUpperCont {
  top: 0;
  z-index: 600;
}

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  .totalPopUpCont {
    top: 13%;
  }
  .totalPopUpUpperCont {
    top: 8%;
  }
  .totalPopUpUpperHotelInfoCont {
    top: 20px;
  }
  .popUpBodyCont,
  .totalPopUpCont,
  .totalPopUpUpperCont {
    z-index: auto;
  }
}
/*---------------------------------------------------*/

.activitesToOptimizeCont{
    background-color: #DEE3EA;
    padding: 15px;
}
.activitesToOptimizeHeader{
  height: 100px;
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
}
.activitesToOptimizeHeader p{
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 17px;
    line-height: 24px;
    /* identical to box height, or 141% */
    
    color: #00264D;
    
}
.indicatorSideBar {
  font-size: 12px;
  color: var(--agendaBtnPrincipal);
}
.indicatorSideBarError{
  
  animation: glow2 1s ease-in-out infinite alternate;
}

@keyframes glow2 {
  from {
    color: #FF5A5F;
    font-size: 12px;
  }

  to {
    color: #106AD4;
    font-size: 16px;
  }
}
.activitesToOptimizeBody{
    padding: 10px;
    height: calc(100% - 100px);
    overflow-y: scroll;
}
.NewInfoPopGeneralCont{
  border-radius: 6px;
  overflow: hidden;
  z-index: 5000;
  width: 100vw;
  height: 100vh;
  box-shadow: 0px 24.1084px 56.6024px rgba(112, 123, 126, 0.51);
}
.NewInfoPopSearchCont {
  background-color: white;

  overflow-y: scroll;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
  border: 5.24096px solid rgba(255, 255, 255, 0.08);
  position: relative;
}
/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  .NewInfoPopGeneralCont{
  border-radius: 56.6024px;
  }
}
.NewInfoPopSearchImg{
  min-height: 100%;
  background-color: white;
  background-image: url("../img/back.avif");
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  
  border-radius: 6px;
  backdrop-filter: blur(24.8571px);
}
.NewInfoPopSearchWhite  {
  min-height: 100%;
  box-sizing: border-box;
  border: 25px solid transparent;
  border-bottom: 0;
  padding-bottom: 0;
  background-color: rgba(255, 255, 255, 0.94);
}

@media (min-width: 768px) {
.NewInfoPopGeneralCont,
.NewInfoPopSearchCont {
  width: 80vw;
  max-width: 990px;
  height: max-content;
  max-height:  calc(100vh - 120px);
}
}
/*-------------------------posiblemente tengamos que eliminarlo-------------------------*/
@media only screen and (max-width: 375px) {
    .InfoPopSearchCont {
      padding: 15px 25px !important;
    }
  }
.InfoPopSearchCont {
  background-color: white;
  z-index: 5000;
  padding: 15px 65px;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
  box-shadow: 2px 7px 30px 5px rgba(0, 38, 77, 0.2);
  border-radius: 6px;
}

@media (min-width: 768px) {
  .InfoPopSearchCont {
    width: 80vw;
    height:  calc(100vh - 83px);
  }
}
.longDescription {
  font-size: 15px;
}

.calendarMaxWidth{
  max-width: 1080px;
  }
/*----------------------------------pop ups custom Input--------------------------------------------*/
.customInputPopUp{
    max-width: 400px;
    padding: 30px 40px;
}
.customInputPopUp p{
    font-weight: 400;
    font-size: 8px;
    line-height: 10px;
    /* or 125% */
    margin-bottom: 0;
    display: flex;
    text-align: left;
    
    color: #000000;
}
.customInputPopUp .titlePopUp{
    font-weight: 700;
    font-size: 20px;
    text-align: left;
    line-height: 20px;
    color: #00264D;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
}
.customInputPopUp .titlePopUp span{
    display: inline-block;
    width: 40px;
    height: 40px;
    
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F2F5F8;
    margin-right: 1.5rem;
    border-radius: 5px;
}
.customInputPopUp .swal2-validation-message{
    height: 0;
    width: 0;
    padding: 0;
    margin: 0;
    color: transparent;
    background-color: transparent;
    overflow: scroll;
}
.customInputPopUp .swal2-validation-message::before{
    display: none!important;
    
}
.customInputPopUp .inputCodeCont {
    display: flex;
    justify-content: center;
    height: 44px;
    margin-bottom: 20px;
}
.customInputPopUp .inputCodeCont input{
    flex-grow: 1;
    height: 100%;
    margin-right: 5px;
    border: 0.6px solid #106AD4;
    border-radius: 3px;
    font-weight: 700;
    line-height: 14px;
    text-align: center;
    color: #000000;
}
.customInputPopUp .inputCodeCont .errorMsg{
    font-weight: 600;
    font-size: 10px;
    line-height: 6px;
    margin-top: 5px;
    /* or 60% */
    
    display: none;
    
    color: #FF5A5F;
}
.customInputPopUp .inputCodeCont input.error{
    border: 0.6px solid #FF5A5F!important;
    color: #FF5A5F!important;
}
.customInputPopUp .inputCodeCont input.error + .errorMsg{
    display: block;
}
.customInputPopUp .inputCodeCont button{
    height: 100%;
    width: 57px;
    background: #106AD4;
    box-shadow: 2px 3px 14px -3px rgba(16, 106, 212, 0.2);
    border-radius: 6px;
    border: none;
}