.btnChangLng {
  background-color: rgba(0, 0, 0, 0);
  color: #fff;
  font-size: 12px;
  border-radius: 50%;
  border: none;
  padding-top: 18px
}
.langBtn {
  margin-top: 23px
}
@media (max-width: 765px) {
  .btnChangLng {
    padding-top: 12px
  }
  .langBtn {
    margin-top: 13px
  }
}
.langBtn .dropdown-menu.show {
  min-width: 112px
}

.langBtnDropCont {
  margin-top: 5px;
  left: auto !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
  right:auto !important;
  outline: none !important;
}
@media (min-width: 768px) { 
  .langBtnDropCont {
    right: 5px !important;
  }

}
.langBtnDropCont::before {
  display: none !important;
  content: "";
  right: 0 !important;
}