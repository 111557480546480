.CityCardCont{
    cursor: pointer;
    width: 199px;
    height: 169px;
    border: 1px solid #E5E8EF;
    box-sizing: border-box;
    border-radius: 5px;
}
.CityCardHeader{
    height: 62%;
    display: flex;
    overflow: scroll;
    border-radius: 5px 5px 0 0;
}
.CityCardBody{
    height: 40%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
}
.CityCardBody h3{

    font-weight: 700;
    font-size: 13px;
    line-height: 15px;
    margin: 0;
    
    color: #00264D;
}
.CityCardBody p{

    margin: 0;
}
.CityCardBody .cityCardCountry{
    font-weight: 400;
    font-size: 8px;
    line-height: 14px;
    /* or 175% */
    
    color: #7E96AE;
}
.CityCardBody .cityCardnumberItems{
    font-weight: 600;
    font-size: 8px;
    line-height: 14px;
    
    color: #106AD4;
}

/**************************************back img*****************************************************/
.principalActi{
    
    background-image: url("../../../img/logo.avif");
    background-color: #ff5757;
    background-clip: border-box;
    height: 100px;
    width: 60%;
    position: relative;
    background-position: center center;
    background-size: cover;
    border-right: 2px solid white;
}
.secundaryActi{
    
    background-image: url("../../../img/logo.avif");
    background-color: #ff5757;
    background-clip: border-box;
    background-color: white;
    height: 100%;
    width: 40%;
    position: relative;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}
.secundaryActi > div{
    
    background-image: url("../../../img/logo.avif");
    background-color: #ff5757;
    background-clip: border-box;
    /*border: 1px solid white;*/
    height: 50%;
    width: 100%;
    position: relative;
    background-position: center center;
    background-size: cover;
}
.secundaryActi > div:first-child{
    
    border-bottom: 2px solid white;
}
::-webkit-scrollbar{
    width: 0px !important;
  }