.iconInputEditUser {
  display: flex;
  justify-content: left;
  align-items: center;
  color: #7e96ae;
  font-size: 10px;
}
.btnGoEdit {
  background-color: transparent;
  border: none;
  color: #106ad4;
  font-weight: 500;
  font-size: 10px;
}
.btnCancel {
  border: 1px solid var(--agendaBtnPrincipal);
  background-color: transparent;
  width: 100%;
  border: none;
  border-radius: 6px;
  height: 38px;
  font-size: 14px;
  font-weight: 600;
  color: var(--agendaBtnPrincipal);
  transition: background 0.3s ease-in;
  cursor: pointer;
}
.confirmHeader h2 {
  margin: 0;
  margin-bottom: 10px;
  text-align: center;
  color: #00264d;
  font-weight: 700;
  font-size: 20px;
}
.confirmHeader p {
  margin: 0;
  margin-bottom: 10px;
  color: #00264d;
  font-weight: 400;
  line-height: 14px;
  font-size: 10px;
  text-align: center;
}
.btnCodeRecovery:hover {
  background-color: #f2f5f8;
  border: 1px solid #106ad4 !important;
}
