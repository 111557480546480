.optionCont {
  left: -10% !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
  right: 5px !important;
  outline: none !important;
}
.optionCont::before {
  display: none !important;
  content: "";
  right: 0 !important;
}
.optionsUserbtn {
  font-size: 14px;
  border: none;
  background-color: transparent;
  text-align: left;
  padding: 7px;
  width: 100%;
  border-radius: 7px;
  font-weight: 400;
  color: #00264d;
  transition: all 0.3s linear;
}
.optionsUserbtn:disabled,
.loginSignbtnDisabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.optionsUserbtn:hover {
  background-color: #e2eefa;
  transition: all 0.3s linear;
  box-shadow: 2px 3px 25px -3px rgba(13, 32, 51, 0.2);
}
.navPop {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 5rem;
}
.navPop > * {
  transition: transform 0.1s ease-in;
}
.navPop > *:hover {
  transform: scale(1.1);
}
.titleUser {
  display: flex;
  align-items: center;
  width: 70%;
}
.titleUser p {
  margin: 0;
}
.userName {
  font-size: 14px;
  font-weight: 600;
}
.userDes {
  color: #00264d;

  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: scroll;
}
.signuptitle {
  color: #00264d;
  font-weight: 700;
  font-size: 16px;
}
.signupcoment {
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  color: #919191;
}
.loginbtnSimple > div,
.signupbtnSimple > div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
}

.loginbtnSimple > div {
  background-color: #106ad4;
  color: white;
  box-shadow: 2px 5px 20px 1px rgba(16, 106, 212, 0.2);
  border-radius: 6px;
  text-align: center;
}
.loginbtnSimple.alt > div {
  background-color: #FF5757!important;
}
.loginbtnSimple p {
  font-weight: 600;
  font-size: 15px;
}
.signupbtnSimple p {
  color: #106ad4;
  font-weight: 600;
  font-size: 15px;
}
.signupbtnSimple:hover,
.loginbtnSimple:hover {
  cursor: pointer;
}
.backgroundTransparent .landingWhite {
  color: #00264d;
  width: 250px;
}
/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  .backgroundTransparent .usercircleLogCont {
    margin-right: 80px;
  }
}
.backgroundTransparent .loginbtnSimple p {
  color: #fff;
}
.backgroundTransparent .loginbtnSimple > div {
  background-color: #106ad4;
  color: white;
  box-shadow: 2px 5px 20px 1px rgba(16, 106, 212, 0.2);
  box-shadow: 2px 5px 20px 3px rgba(16, 106, 212, 0.2);
  border-radius: 6px;
  text-align: center;
}
.backgroundTransparent .signupbtnSimple p {
  color: #000;
}
.loginbtn,
.signupbtn {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loginbtn {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #00264d;
}
.signupbtn {
  padding: 4px;
  background-color: #f2f5f8;
  border-radius: 5px;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #106ad4;
}
