.number{
  font-weight: 600;
  font-size: 50px;
  color: #FF5A5F;
  margin-bottom: 10px;
}
.steps{
  vertical-align: top;
}
.footerCont {
  width: 100vw;
  z-index: 6;
  position: fixed;
  bottom: 0;
  padding: 10px;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #00264D;
  transform: rotateY(0)
}

@media (min-width: 768px) {
  .footerCont {
    padding: 0 95px 20px
  }
}

.footerCont a {
  display: block;
  font-size: 8px;
  color: #00264D;
  width: 100%;
  transition: color .3s ease-in-out;
  text-align: center
}

@media (min-width: 576px) {
  .footerCont a {
    font-size: 10px
  }
}

@media (min-width: 768px) {
  .footerCont a {
    font-size: 13px
  }
}

.footerCont a:hover {
  text-decoration: none;
  color: #00264D
}

.footerBcont {
  min-width: 145px;
  max-width: 165px;
  height: 20px;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  display: flex;
  align-items: center;
  transition: all .3s ease
}

.footerBcont:hover {
  font-weight: 600;
  align-items: center;
  text-align: right;
  display: flex;
  align-items: center
}

.line {
  left: 270px;
  top: 843px;
  margin: 0;
  border: .6px solid #00264D
}

.footerBcont,
.footerAcont {
  display: flex;
  justify-content: center;
  align-items: center
}

@media only screen and (max-width: 765px) {

  .footerBcont,
  .footerAcont {
    padding: 0
  }
}