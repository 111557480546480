.bellCont{
    width: 30px;
    position: relative;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #EDF0F4;
    border-radius: 100%;
}
.numberNotificationsBell{
    position: absolute;
    font-size: 10px;
    font-weight: 600;
    line-height: 16px;
    width: 19px;
    height: 19px;
    background-color: #FF0035;
    color:white;
    border: 1px solid white;
    border-radius: 100%;
    right: -5px;
    top: -5px;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-left: 1px;
}
/* menu notification*/
.notificationMenu{
    box-shadow: 2px 3px 16px -3px rgb(0 38 77 / 20%);
    padding: 10px 20px;
    display: block!important;
    width: 350px!important;
    top:20px !important;
    left: -10px!important;
    right: 0!important;
    border: none!important;

}
.notificationMenu > div{
    display: block!important;

}
.notificationMenu > .listCont{
    max-height: 190px;
    overflow: scroll;

}
.notificationMenu > .listExpanded{
    max-height: 390px;
    overflow-y: scroll;

}
.notificationMenu::before{
    display: none!important;
    
  content: "";
  right: 0 !important;
    
}
.notificationMenuTitle{
    

font-family: Poppins;
font-style: normal;
font-weight: bold;
font-size: 10px;
line-height: 22px;
/* or 220% */

display: flex;
align-items: center;

color: #00264D;
}
.notificationCont{
    margin: 0;
    margin-bottom: 12px;
    padding: 15px;
    background: #F2F5F8;
    box-shadow: 2px 3px 16px -3px rgba(0, 38, 77, 0.2);
    border-radius: 7px;
}
.notificationContUnread{
    background: #1ebb6c3b;
}
.notificationIcon{
    padding: 0;
    justify-content: center;
    align-items: center;
}
.notificationIcon > div{
    
    width: 15.81px;
    height: 15.81px;
    border-radius: 100%;

}
.greenCircle{
    background-color: #1EBB6D;
}
.redCircle{
    background-color: #FF5757;
}
.notificationMsg{
    font-weight: normal;
    font-size: 10px;
    line-height: 22px;
    /* or 220% */

    display: flex;
    align-items: center;

    color: #00264D;
}
.notificationDate{
    
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 22px;
    /* or 220% */

    display: flex;
    align-items: center;

    color: #00264D;
}
.seeAllBtn{
    
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 22px;
    /* or 220% */

    display: flex;
    align-items: center;
    text-align: center;

    color: #106AD4!important;
}