:root {
  --agendaPrincipal: #edf0f4;
  --agendaBtnPrincipal: #106ad4;
  --agendaBtnPrincipalHover: #148ef1;
  --agendaPrincipalTrans: #edf0f46e;
  --agendaSecundario: #919191;
}

.circleText{
  display: inline-block;
  width: 5px;
  height: 5px;
  margin-bottom: 5px;
  margin-left: .5rem;
  margin-right: .5rem;
  border-radius: 100%;
  background-color: #106AD4;
}
.doubtBtn {
  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: underline;
  cursor: pointer;
  left: 7vw;
}
.h-inherit {
  height: inherit;
}
/*
a:visited {
  color: #2e363d!important;
}
a:Active {
  color: #2e363d!important;
}
*/
.tooltip.show{
  z-index: 50000;
}
.btnCrear {
  background-color: var(--agendaBtnPrincipal);
  width: 100%;
  border: none;
  border-radius: 6px;
  height: 38px;
  font-size: 14px;
  font-weight: 600;
  color: white;
  transition: background 0.3s ease-in;
  cursor: pointer;
}
button:disabled {
  opacity: 0.5;
}
/*-------------------------qrbutton---------------------------*/
.qrButton{
  
  position:fixed;
  left: 80px;
  bottom: 30px;
  z-index: 1;
}
.qrButton > *{
  
  text-align: right;
}
.qrButton > h4{
  
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 0;
  /* or 138% */


  color: #00264D;
}
.qrButton > p{
  margin-bottom: 0;
  font-weight: 900;
  font-size: 13px;
  line-height: 18px;
  /* or 138% */
  cursor: pointer;
  color: #FF5A5F;
}
.agencyContact{
  
  position:fixed;
  left:400px;
  bottom: 30px;
  z-index: 1;
}
.agencyContact > *{
  
  text-align: right;
}
.agencyContact > h4{
  
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 0;
  /* or 138% */


  color: #00264D;
}
.agencyContact > p{
  margin-bottom: 0;
  font-weight: 900;
  font-size: 13px;
  line-height: 18px;
  /* or 138% */
  cursor: pointer;
  color: #FF5A5F;
}
.agencyRegister{
  left: 400px;
  position:fixed;
  bottom: 30px;
  z-index: 1;
}
.agencyRegister > *{
  
  text-align: right;
}
.agencyRegister > h4{
  
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 0;
  /* or 138% */


  color: #00264D;
}
.agencyRegister > p{
  margin-bottom: 0;
  font-weight: 900;
  font-size: 13px;
  line-height: 18px;
  /* or 138% */
  cursor: pointer;
  color: #FF5A5F;
}
/*-------------------------------------------------------------*/
.btnCrear:hover {
  background-color: var(--agendaBtnPrincipalHover);
}
.disabledStyle,
.btnCrear:disabled {
  cursor: no-drop;
  opacity: 0.5;
}

.emojiCont img {
  margin-top: -3px;
  height: 20px;
}
.container {
  width: 95%;
  margin: 0 auto;
}

.container {
  /*ocultar scroll mozilla y ie*/
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
}
.agenda__table {
  /*ocultar scroll mozilla y ie*/
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
}
input:focus {
  outline: none;
}
.calendarAgendaCont {
  /*ocultar scroll mozilla y ie*/
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
}
.calendarCont {
  /*ocultar scroll mozilla y ie*/
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
}
.cursor-pointer:hover {
  cursor: pointer;
}

.blackdiv::after {
  content: "";
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 2, 0.4);
}
.modal-overlay {
  position: absolute;
  top: 10%;
  left: 10%;
  width: 80%;
  height: 80%;
  background: white;

  transition: var(--transition);
  visibility: hidden;
  z-index: -1;
}
.show-modal {
  visibility: visible;
  z-index: 10;
}
.backgroundPopUp {
  display: none;
  position: absolute;
  z-index: 600;
  /*
  min-height: 100vh;
  min-width: 100vw;
  */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.349);
}
/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  .flex-grow-md-0{
    flex-grow: 0!important;
  }
  .backgroundPopUp {
    display: block;
  }
}
::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  border-radius: 58px;
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background: #7e96ae;
  border-radius: 4px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #5e6f80;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}
::-webkit-scrollbar-thumb:active {
  background-color: #5e6f80;
} /*
.container::-webkit-scrollbar {
  display: none;
}
.agenda__table::-webkit-scrollbar {
  display: none;
}*/
.calendarAgendaCont::-webkit-scrollbar {
  display: none;
}
.hiddenScroll::-webkit-scrollbar {
  display: none;
}
.calendarCont::-webkit-scrollbar {
  display: none;
}
.noMargin {
  margin: 0 !important;
}
.displayNone {
  display: none;
}
.text-ellipsis-low {
  overflow: scroll;
  text-overflow: ellipsis;
}
.optionCont {
  background-color: white !important;
  box-shadow: 2px 7px 25px 5px rgb(0 38 77 / 20%) !important;
  border-radius: 9px !important;
}

.dropdown-clear {
  padding: 0 !important;
  background-color: transparent !important;
  box-shadow: none !important;
  border: none !important;
}
.clearBtn {
  padding: 0;
  background: none;
  border: none;
}
.clearBtn:hover {
  cursor: pointer;
}

.switch {
  position: relative;
  background-color: transparent;
  border: 1px solid #5a6978;
  border-radius: 100px;
}
.switch:hover {
  cursor: pointer;
}
.switch > div {
  position: absolute;
  left: 0;
  top: 0;
  background-color: #969faa;
  border-radius: 100%;
  transition: all 0.1s ease-in;
}
.switchActive {
  background-color: #47525e;
}
.switchActive > div {
  transform: translateX(65%);
}
.clearfix:after {
  content: " "; /* Viejos browser que no soportan contenido vacío */
  visibility: hidden;
  display: block;
  height: 0;
  clear: both;
}
.calendarSectionCont {
  display: flex;
}
.bgImage {
  background-color: gray;
  background-image: url("../img/test.avif");
  background-position: center center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100vh;
  width: 100vw;
}

.optionAutoComplete {
  padding: 7px 14px;
  font-weight: 400;
  font-size: 14px;
  /*text-transform: capitalize;*/
  cursor: pointer;
  border-radius: 5px;
  color: #00264d;
  transition: all 0.3s ease-in-out;
}
.optionAutoCompleteCity {
  padding: 7px 14px;
  /*text-transform: capitalize;*/
  cursor: pointer;
  border-radius: 5px;
  color: #00264d;
  transition: all 0.3s ease-in-out;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  
  color: #00264D;
}
.optionAutoComplete:last-child {
  margin-bottom: 0;
}

.optionAutoComplete span {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  
  color: #7E96AE;
}
.optionAutoComplete .textResalt {
  display: inline-block;
  text-emphasis-color: transparent;
  padding: 0;
  font-weight: 600;
}
.optionAutoComplete:hover span {
  color: #106ad4;
}
.setPadding {
  position: relative;
  padding-top: 55px;
}
/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  .setPadding {
    padding-top: 70px;
  }
}
.iframe-calendar {
  
  &.setPadding {
    position: relative;
    padding-top: 55px;
  }
  /* Medium devices (tablets, 768px and up)*/
  @media (min-width: 768px) {
    &.setPadding {
      padding-top: 0px;
    }
  }
}
.bckColor {
  background: linear-gradient(
    250.41deg,
    rgba(242, 244, 248, 0.88) 4.71%,
    #ede0ff 83.08%
  );
}
.principalBackground {
  /*
    background: rgb(125,185,232); 
    background: -moz-linear-gradient(-45deg,  var(--agendaBtnPrincipal) 0%, var(--agendaBtnPrincipal) 49%, var(--agendaSecundario) 99%);
    background: -webkit-linear-gradient(-45deg,  var(--agendaBtnPrincipal) 0%,var(--agendaBtnPrincipal) 49%,var(--agendaSecundario) 99%); 
    background: linear-gradient(135deg,  var(--agendaBtnPrincipal) 0%,var(--agendaBtnPrincipal) 49%,var(--agendaSecundario) 99%); 
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7db9e8', endColorstr='#1e5799',GradientType=1 ); 
    */
  min-height: 100vh;
  background-image: url("../img/mapa_back.avif");
  background-size: 1300px;
  background-position: bottom right;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
}
.principalBackground::after {
  content: "";
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 2, 0.05);
}
.viewCont {
  height: calc(100vh - 10.8rem);
  display: flex;
}
.sharingPopUp {
  height: 100%;
  width: 100%;
  background-color: white;
}

.appbarBackground {
  background-color: white;
}
/*------------------------------SEARCHPAGE CONTAINERS---------------------*/

.searchActCont {
  margin: 35px 0 0px 0;
  padding: 10px 5px;
  background-color: white;
  border-radius: 7px;
  height: calc(100vh - 100px);
  /*width: 90vw;*/
}

.searchCalCont {
  /*width: calc(100vw - 290px);*/
  z-index: 1;
  margin: 15px 0 0px 0;
  height: calc(100vh - 85px);
}
@media only screen and (max-width: 768px) {
  .searchActCont {
    margin: 15px 0 0px 0;
    padding: 0;
  }

  .searchCalCont {
    width: 100vw;
    margin: 0;
    height: calc(100vh - 55px);
  }
}
@media only screen and (max-width: 375px) {
  .searchActCont {
    margin: 0;
  }
  .doubtBtn {
    margin-left: 10vw;
    top: 25vw;
  }
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type=number] { -moz-appearance:textfield; }
/*rangeinputs*/
input[type="range"] {
  width: 100%;
  margin: 5.85px 0;
  background-color: transparent;
  -webkit-appearance: none;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  background: #106ad4;
  border: 1.1px solid #106ad4;
  border-radius: 1px;
  width: 100%;
  height: 5px;
  cursor: pointer;
}
input[type="range"]::-webkit-slider-thumb {
  margin-top: -6.95px;
  width: 23px;
  height: 23px;
  background: #ffffff;
  border: 2.5px solid #106ad4;
  border-radius: 12px;
  cursor: pointer;
  -webkit-appearance: none;
}
input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #106ad4;
}
input[type="range"]::-moz-range-track {
  background: #106ad4;
  border: 1.1px solid #106ad4;
  border-radius: 1px;
  width: 100%;
  height: 5.3px;
  cursor: pointer;
}
input[type="range"]::-moz-range-thumb {
  width: 23px;
  height: 23px;
  background: #ffffff;
  border: 2.5px solid #106ad4;
  border-radius: 12px;
  cursor: pointer;
}
input[type="range"]::-ms-track {
  background: transparent;
  border-color: transparent;
  border-width: 8.45px 0;
  color: transparent;
  width: 100%;
  height: 5.3px;
  cursor: pointer;
}
input[type="range"]::-ms-fill-lower {
  background: #106ad4;
  border: 1.1px solid #106ad4;
  border-radius: 2px;
}
input[type="range"]::-ms-fill-upper {
  background: #106ad4;
  border: 1.1px solid #106ad4;
  border-radius: 2px;
}
input[type="range"]::-ms-thumb {
  width: 23px;
  height: 23px;
  background: #ffffff;
  border: 2.5px solid #106ad4;
  border-radius: 12px;
  cursor: pointer;
  margin-top: 0px;
  /*Needed to keep the Edge thumb centred*/
}
input[type="range"]:focus::-ms-fill-lower {
  background: #106ad4;
}
input[type="range"]:focus::-ms-fill-upper {
  background: #106ad4;
}
/*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
how to remove the virtical space around the range input in IE*/
@supports (-ms-ime-align: auto) {
  /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
  input[type="range"] {
    margin: 0;
    /*Edge starts the margin from the thumb, not the track as other browsers do*/
  }
}
input[type="range"] {
  width: 100%;
  margin: 5.85px 0;
  background-color: transparent;
  -webkit-appearance: none;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  background: #106ad4;
  border: 1.1px solid #106ad4;
  border-radius: 1px;
  width: 100%;
  height: 5px;
  cursor: pointer;
}
input[type="range"]::-webkit-slider-thumb {
  margin-top: -6.95px;
  width: 23px;
  height: 23px;
  background: #ffffff;
  border: 2.5px solid #106ad4;
  border-radius: 12px;
  cursor: pointer;
  -webkit-appearance: none;
}
input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #106ad4;
}
input[type="range"]::-moz-range-track {
  background: #106ad4;
  border: 1.1px solid #106ad4;
  border-radius: 1px;
  width: 100%;
  height: 5.3px;
  cursor: pointer;
}
input[type="range"]::-moz-range-thumb {
  width: 23px;
  height: 23px;
  background: #ffffff;
  border: 2.5px solid #106ad4;
  border-radius: 12px;
  cursor: pointer;
}
input[type="range"]::-ms-track {
  background: transparent;
  border-color: transparent;
  border-width: 8.45px 0;
  color: transparent;
  width: 100%;
  height: 5.3px;
  cursor: pointer;
}
input[type="range"]::-ms-fill-lower {
  background: #106ad4;
  border: 1.1px solid #106ad4;
  border-radius: 2px;
}
input[type="range"]::-ms-fill-upper {
  background: #106ad4;
  border: 1.1px solid #106ad4;
  border-radius: 2px;
}
input[type="range"]::-ms-thumb {
  width: 23px;
  height: 23px;
  background: #ffffff;
  border: 2.5px solid #106ad4;
  border-radius: 12px;
  cursor: pointer;
  margin-top: 0px;
  /*Needed to keep the Edge thumb centred*/
}
input[type="range"]:focus::-ms-fill-lower {
  background: #106ad4;
}
input[type="range"]:focus::-ms-fill-upper {
  background: #106ad4;
}
/*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
how to remove the virtical space around the range input in IE*/
@supports (-ms-ime-align: auto) {
  /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
  input[type="range"] {
    margin: 0;
    /*Edge starts the margin from the thumb, not the track as other browsers do*/
  }
}
input[type="range"] {
  width: 100%;
  margin: 5.85px 0;
  background-color: transparent;
  -webkit-appearance: none;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  background: #106ad4;
  border: 1.1px solid #106ad4;
  border-radius: 1px;
  width: 100%;
  height: 5px;
  cursor: pointer;
}
input[type="range"]::-webkit-slider-thumb {
  margin-top: -6.95px;
  width: 23px;
  height: 23px;
  background: #ffffff;
  border: 2.5px solid #106ad4;
  border-radius: 12px;
  cursor: pointer;
  -webkit-appearance: none;
}
input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #106ad4;
}
input[type="range"]::-moz-range-track {
  background: #106ad4;
  border: 1.1px solid #106ad4;
  border-radius: 1px;
  width: 100%;
  height: 5.3px;
  cursor: pointer;
}
input[type="range"]::-moz-range-thumb {
  width: 23px;
  height: 23px;
  background: #ffffff;
  border: 2.5px solid #106ad4;
  border-radius: 12px;
  cursor: pointer;
}
input[type="range"]::-ms-track {
  background: transparent;
  border-color: transparent;
  border-width: 8.45px 0;
  color: transparent;
  width: 100%;
  height: 5.3px;
  cursor: pointer;
}
input[type="range"]::-ms-fill-lower {
  background: #106ad4;
  border: 1.1px solid #106ad4;
  border-radius: 2px;
}
input[type="range"]::-ms-fill-upper {
  background: #106ad4;
  border: 1.1px solid #106ad4;
  border-radius: 2px;
}
input[type="range"]::-ms-thumb {
  width: 23px;
  height: 23px;
  background: #ffffff;
  border: 2.5px solid #106ad4;
  border-radius: 12px;
  cursor: pointer;
  margin-top: 0px;
  /*Needed to keep the Edge thumb centred*/
}
input[type="range"]:focus::-ms-fill-lower {
  background: #106ad4;
}
input[type="range"]:focus::-ms-fill-upper {
  background: #106ad4;
}

::placeholder {
  font-family: "Font Awesome 5 Free", "Poppins", sans-serif;
  font-weight: 400;
}
input:focus {
  background-color: #f2f5f8 !important;
  border: 1px solid var(--agendaBtnPrincipal) !important;
}
.inputCity:focus {
  background-color: white !important;
  border: none !important;
}
/*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
how to remove the virtical space around the range input in IE*/
@supports (-ms-ime-align: auto) {
  /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
  input[type="range"] {
    margin: 0;
    /*Edge starts the margin from the thumb, not the track as other browsers do*/
  }
}
/*------------------------icons-----------*/
.calendarIcon {
  display: inline-block;
  margin: 0;
  width: 15px;
  height: 15px;
  background-image: url("../img/calendar.avif");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

/*-----------padding---------*/
.paddingInfo {
  padding: 0 20px;
}
@media (min-width: 576px) {
  .paddingInfo {
    padding: 0 40px;
  }
}

/*/ Medium devices (tablets, 768px and up)/*/
@media (min-width: 768px) {
  .paddingInfo {
    padding: 0 60px;
  }
}

/*/ Large devices (desktops, 992px and up)/*/
@media (min-width: 992px) {
  .paddingInfo {
    padding: 0 90px;
  }
}

/*/ Extra large devices (large desktops, 1200px and up)/*/
@media (min-width: 1200px) {
  .paddingInfo {
    padding: 0 100px;
  }
}

/*-------------------------------------popupclases---------------**/
.swal2-content {
  padding: 0 !important;
}
.swal2-container{
  z-index: 5000!important;
}
.confirmName {
  max-width: 350px !important;
  min-width: 150px !important;
}

/*/ Medium devices (tablets, 768px and up)/*/
@media (min-width: 768px) {
  .confirmName {
    width: 48% !important;
  }
}

.confirmName .swal2-actions {
  margin: 0 !important;
  margin-top: 20px !important;
  flex-direction: row-reverse;
  justify-content: space-around !important;
}
.confirmName .swal2-actions > button {
  width: 40%;
  font-size: 13px;
  transition: all 0.3s linear;
  height: 38px !important;
  padding: 0 !important;
  margin: 0 !important;
  outline: none;
}
.confirmName .swal2-actions > button:focus {
  box-shadow: none;
}
.confirmName .swal2-input {
  min-height: 24px !important;
  font-size: 10px;
  margin-bottom: 0px;
}
.confirmName .swal2-select {
  min-height: 24px !important;
  font-size: 10px;
  margin-bottom: 20px;
}
.confirmName .swal2-cancel,
.confirmName .swal2-deny {
  font-size: 14px;
  font-weight: 600;
  color: #106ad4 !important;
}
.confirmName .swal2-validation-message {
  margin: 0;
  font-size: 12px;
  background-color: transparent;
}
.sweetPopHeader {
  border-bottom: 0.5px solid #dee3ea;
}
.sweetPopTittle {
  font-weight: 700;
  text-align: left;
  font-size: 14px;
  color: #00264d;
}
.sweetPopSubTittle {
  font-weight: 500;
  text-align: left;
  font-size: 10px;
  color: #00264d;
}
.toastCont {
  color: #00264d;
  display: block;
  font-size: 25px;
  margin-top: 1rem;
  font-family: "Font Awesome 5 Free", "Poppins", sans-serif;
}
/*Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1300px) {
  .sweetPopTittle {
    font-size: 16px;
  }
  .sweetPopSubTittle {
    font-size: 12px;
  }
}
.clearDropMenu {
  background-color: transparent !important;
  box-shadow: none !important;
  border: none !important;
}
.hideDropDownArrow::before {
  display: none !important;
  content: "";
}
.hideDropDownArrow::after {
  display: none !important;
  content: "";
}
.hideDropDownArrow .arrow::before {
  display: none !important;
  content: "";
}
.hideDropDownArrow .arrow::after {
  display: none !important;
  content: "";
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #f2f5f8 inset !important;
}
.SearchInfoCont {
  border-radius: 20px;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
}
.BussinessDashboardBody{
  position: relative;
  overflow-y: scroll;
  height: calc(100vh - 70px);
  padding: 20px 30px;
}
/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) { 
    .SearchInfoCont {
    
    max-width: 400px;
    }
}
.searchinput::placeholder {
  display: flex;
  text-align: center;
}
/*others*/
.globeInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 7px;
  height: 15px;
  width: 15px;
  border-radius: 100%;
  color: #106ad4;
  box-sizing: border-box;
  background: #f2f5f8;
  border: 0.7px solid #106ad4;
}
.rotate180{
  transform: rotateX(180deg);
}
.arrow::before{
  border-bottom-color:#000!important;
  border-top-color:#000!important;
}
.arrowPop .arrow::before{
  opacity: 0.5;
}







.blogTitle {
  font-weight: 700;
  font-size: 55px;
  text-align: left;
  line-height: 105px;
  width: 35%;
  margin-left: 55px;
  position: relative;
  top: 80px;

  background-size: 1px;
  text-shadow: 3px 4px 16px rgba(0, 0, 0, 0.25);
}
.blogSubTitle {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  position: relative;
  top: 100px;
  margin-left: 55px;
  width: 45%;
  border-left: 5px solid #00A699;
  padding-left: 5px;
}
@media only screen and (max-width: 376px) {
  .blogTitle{
    font-size: 25px;
    line-height: 47px;
    width: 60%;
    margin-left: 20px;
  }
  .blogSubTitle{
    font-size: 15px;
    line-height: 25px;
    width: 85%;
    margin-left:20px;
  }
}





.blog{
  overflow: auto;
  scroll-behavior: smooth;
}
.recomContainer{
  width: 99%; 
  margin-left:30px; 
  position:relative;
}
.artBtn{
    background: #6246EA;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px 23px 19px;
    gap: 10px;    
    border-radius: 8px;
    color: #FFFFFE;
    margin-top: 2%;
    border: none;
    margin-left: 42%;
}
@media only screen and (max-width: 768px) {
  .artBtn {
    margin-left: 32%;
  }
}
@media only screen and (max-width: 376px) {
  .recomContainer {
    margin-left: 12px;
  }
  .artBtn{
    width: 176px;
    height: 48px;
    margin-left: 20%;
  }
}
@media only screen and (min-width: 1080px) {
  .wrapperCat {
      justify-content: center;
    }
}

.wrapperCat{
  overflow-x: auto;
  overflow-y: none;
  max-height: 280px;
  white-space: nowrap;
  display: flex;
}
.wrapperCat::-webkit-scrollbar{
  width: 0;
}

/*-----------------------------------------------------reserveMsg--------------------------*/
.sectionReserveMsgTitle{
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  text-align: left;
  
  color: #00264D;
}
.ReserveMsgPop {
  
  box-shadow: 2px 7px 30px 5px rgba(0, 38, 77, 0.2);
  border-radius: 9px!important;
  padding: 45px 35px 25px 35px!important;
}
.ReserveMsgPop li {
  
  font-weight: 500;
  font-size: 15px;
  line-height: 30px;
  /* or 200% */

  text-align: left;

  color: #00264D;
}
.ReserveMsgPop .swal2-actions {
  justify-content: end;
  margin-top: 0;
}
.ReserveMsgPop .swal2-actions .swal2-confirm {
  width: 90.69px;
  height: 38.79px;
  display: inline-flex!important;
  justify-content: center;
}
.ReserveMsgPop li span{
  
  font-weight: 700;
}