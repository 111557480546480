.appbarCont {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0px;
    right: 0px;
    /*min-width: 870px;*/
    height: 55px;
    transition: all 0.3s linear;
    box-shadow: 0px 0px 11px rgba(138, 142, 146, 0.3);
  }
  @media (min-width: 768px) {
    .appbarCont {
      height: 70px;
      position: fixed;
    }
  }
  .appbarCont > div {
    position: relative;
  }
  .backgroundTransparent {
    top: 0px;
    padding-top: 10px;
    box-shadow: none;
    &.set-hover:hover{
      background-color: rgba(255, 255, 255, 0.806);
    }
  }
  @media only screen and (max-width: 780px) {
    .backgroundTransparent {
      top: 0px;
      left: 0px;
      right: 0px;
    }
    .langBtn {
      left: 22px;
    }
  }
  .headerCont {
    width: 100vw;
    background-color: transparent;
    padding: 2rem 3rem 1rem 3rem;
    justify-content: space-between;
    font-weight: 600;
    color: #26292e;
    transform: rotateY(0);
    justify-content: flex-start;
    align-items: center;
  }
  .headerCont a {
    display: block;
    font-size: 13px;
    color: #26292e !important; /*#919191;*/
    width: 100%;
    transition: color 0.3s ease-in-out;
    text-align: center;
    padding: 0.5rem 0 0 0;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
  }
  .headerCont a:hover {
    text-decoration: none;
    color: #26292e !important;
  }
  .headerCont button {
    width: 147px;
    height: 44px;
    position: relative;
    background: #106ad4;
    border-style: none;
    box-shadow: 2px 5px 20px 3px rgba(16, 106, 212, 0.2);
    border-radius: 6px;
    color: white;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    align-items: center;
    text-align: center;
  }
  /*
  .appbarBackground {
          /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#7db9e8+0,7db9e8+49,1e5799+99 */
  /*background: var(--agendaPrincipal);
  }*/
  .appbarLogo {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    width: 70px;
    height: 55px;
    &> .sublogo {
      color: #00264d !important
    }
  }
  
  /* Medium devices (tablets, 768px and up)*/
  @media (min-width: 768px) {
    .appbarLogo {
      background-color: #ff5757;
      margin-left: 80px;
      height: 70px;
    }
  }
  
  .appbarBackground .appbarLogo {
    margin: 0;
  }
  .appbarLogo img {
    display: flex;
    margin: 0;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 35px;
  }
  .backgroundTransparent .appbarLogo {
    background-color: transparent;
  }
  
  .appbarLogo:hover {
    cursor: pointer;
  }
  .logoSearchCont {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  /*-----------------------------------------------search---------------------------------*/
  .searchInputCont {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: #ffffff;
  }
  .searchAppbar {
    margin-left: 10px;
    border-radius: 100px;
    width: 178px;
    height: 25px;
  }
  .searchIcon {
    position: absolute;
    left: 5%;
    top: 25%;
  }
  .searchInput {
    border-radius: 100px;
    border: none;
    width: 83%;
    height: 100%;
  }
  .searchInput,
  .searchInput:hover,
  .searchInput:focus,
  .searchInput:active {
    border: none;
  }
  
  /*-----------------------------------------------users--------------------------------*/
  .navUserCont {
    display: flex;
    justify-content: flex-end;
  }
  .usersCircleCont {
    margin-left: 50px;
    display: flex;
    justify-content: space-around;
  }
  /*----------------------------------------save--------------------------*/
  .saveCont {
    margin-left: 10px;
    display: flex;
  }
  .navAppbar {
    color: #7e96ae;
    position: relative;
    font-weight: 400;
  }
  @media only screen and (min-width: 1800px) {
    .navAppbar {
      font-size: 15px;
    }
  } /*
  .navAppbar::after{
      content: '';
      position: absolute;
      bottom: -10px;
      left: 25%;
      display: block;
      background-color: #26292E;
      width: 50%;
      max-width: 0;
      height: 3px;
      border-radius: 20px;
      transition: max-width .5s ease-in-out;
  }*/
  .backgroundTransparent .navAppbar::after {
    background-color: #00264d;
  }
  .navAppbar:hover:after,
  .selectedPage:after {
    max-width: 100%;
  }
  .navAppbarMobileCont {
    display: none !important;
  }
  .navAppbarMobile {
    color: #00264d !important;
    position: relative;
    font-weight: 600;
  }
  .navAppbarMobile::after {
    content: "";
    position: absolute;
    bottom: 0px;
    right: 0%;
    display: block;
    background-color: #fff;
    width: 50%;
    max-width: 0;
    height: 3px;
    border-radius: 20px;
    transition: max-width 0.5s ease-in-out;
  }
  .navAppbarMobile:hover:after {
    max-width: 100%;
  }
  
  .navAppbar:visited {
    color: #7e96ae !important;
  }
  .selectedPage:visited {
    text-decoration: none;
    color: #26292e !important;
  }
  .navAppbarMobile:visited {
    color: #fff !important;
  }
  
  .backgroundTransparent .navAppbar {
    color: #00264d !important;
  }
  .backgroundTransparent .navAppbar:visited {
    color: #00264d !important;
  }
  .forceBackWhite {
    background-color: white !important;
  }
  .usercircleLogCont {
    width: max-content;
    display: none;
    width: 0;
    min-width: auto;
  }
  /* Medium devices (tablets, 768px and up)*/
  @media (min-width: 768px) {
    .usercircleLogCont {
      display: flex;
    }
  }
  .navAppbar {
    display: none;
  }
  /*.usercircleLogCont * {
    display: none;
  }*/
  .navAppbarMobileCont {
    display: flex;
  }
  .mobilemenu {
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
  }
  
  @media only screen and (min-width: 768px) {
    .mobileMenuCollapse {
      max-width: 0 !important;
    }
  
    .usercircleLogCont {
      margin: 5px 0;
      max-width: 370px;
      min-width: 230px;/*175px;*/
    }
    .navAppbar {
      display: flex !important;
      justify-content: center;
      align-items: center;
    }
    /*.usercircleLogCont * {
      display: flex;
    }*/
    .navAppbarMobileCont {
      display: none !important;
    }
    .mobilemenu {
      display: none !important;
    }
  }
  .mobileMenuCollapse {
    z-index: 57;
    position: absolute;
    overflow: scroll;
    transition: all 0.7s ease-in-out;
    top: 55px;
    left: 0;
    right: 0;
  }
  .mobileMenuCont {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 55px);
    box-shadow: 2px 7px 30px 5px rgb(0 38 77 / 20%);
    border-radius: 5px;
    padding: 15px;
    padding-bottom: 75px;
  }
  @media (max-height: 600px) {
    .mobileMenuCont {
      padding-bottom: 15px;
    }
  }
  
  .optionsmobileUserbtn {
    padding: 8px;
    cursor: pointer;
    border: none;
    background: #ffffff;
    text-align: center;
    transition: all 0.3s linear;
    box-shadow: 2px 3px 25px -3px rgba(0, 38, 77, 0.2);
    border-radius: 7px;
    color: #00264d;
    font-weight: 400;
    width: 100%;
    font-size: 14px;
  }
  .optionsmobileUserbtnDisabled{
    cursor: not-allowed;
    opacity: 0.5;
  }
  /*.optionsmobileUserbtn:hover, */
  .optionsmobileUserbtnSelected {
    background: #e2eefa;
  }
  .smallBtnMobile {
    font-size: 11px;
  }
  /* medias querys*/
  @media (min-width: 576px) {
    .smallBtnMobile {
      font-size: 14px;
    }
  }
  /*
  .navAppbar:hover {
      color: #00264D!important;
  }
  .backgroundTransparent .navAppbar:hover{
      color: #00264D!important;
  }*/
  .logOutBtn {
    background-color: transparent;
    border: none;
    color: #000;
    font-size: 15px;
    font-weight: 600;
  }
  .loginbtnMobile > div,
  .signupbtnMobile > div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 22px;
  }
  .loginbtnMobile p {
    color: #00264d;
    font-weight: 600;
    font-size: 15px;
  }
  .signupbtnMobile p {
    color: #fff;
    font-weight: 600;
    font-size: 15px;
  }
  .userNameMob {
    padding: 7px;
    font-size: 14px;
    font-weight: 600;
  }
  .userDesMob {
    font-size: 12px;
    font-weight: 400;
  }
  .userDesMob,
  .userNameMob {
    color: #00264d;
    text-align: end;
  }
  