.userCircleCont {
    display: flex;
    border-radius: 100%;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin: .5rem;


}

.imgUser {
    border-radius: 100%;
    background-color: #FF5757;
    color: white;
    font-weight: 600;
    overflow: scroll;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 9px rgba(0, 38, 77, 0.2);
}
.userCircleFooter > * {
    text-align: center;
}
.borderGray {
    border: 3px solid #EDF0F4;
}