
  .popUpQrCont {
    background-color: white;
    min-width: 300px !important;
    z-index: 50000;
    background-color: white;
    width: 100vw;
    height: 100vh;
    padding: 30px;
    overflow-y: scroll;
    padding-bottom: 75px;
    box-shadow: 2px 7px 30px 5px rgba(0, 38, 77, 0.2);
    border-radius: 9px;
    animation: slideInUp; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 0.3s; /* don't forget to set a duration! */
  }
  @media (min-width: 768px) {
    .popUpQrCont {
      width: 90%;
      
    padding-bottom: 30px;
      height: calc(90vh - 70px);
      
        max-width: 840px;
        max-height: 500px;
      animation: zoomIn; /* referring directly to the animation's @keyframe declaration */
      animation-duration: 0.3s; /* don't forget to set a duration! */
    }
  }
  .infoQrcont {
    position: relative;
  }
.infoQrcont::after{
    content: '';
    display: block;
    position: absolute;
    right: 0;
    top: 80px;
    width: 1px;
    height: 150%;
}
@media (min-width: 768px) {
    .infoQrcont::after{
        border-right: 0.5px solid #DEE3EA;
    }
  }
.infoQrcont h2{
    margin-bottom: 15px;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height, or 120% */
    
    color: #00264D;
}
.infoQrcont h2 span {
    
    color: #FF5757;
}
.infoQrcont p {
font-weight: 400;
font-size: 14px;
line-height: 20px;
/* or 143% */


color: #00264D;
}
/*form*/
.formQrCont{
    position: relative;
    padding-bottom: 25px;
}
.formQrCont::after{
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0px;
    height: 1px;
    width: 90%;
}

@media (min-width: 768px) {
    .formQrCont::after{
        border-bottom: 0.5px solid #DEE3EA;
    }
  }
.emojiQrCont{
    width: 18px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
.inputQrCont{
    
    width: 80%;
    margin-bottom: 10px;
}
.inputQrCont label{
    
font-weight: 500;
font-size: 14px;
line-height: 14px;
/* identical to box height, or 100% */


color: #00264D;
}
.inputQrCont >div{
border: 0.6px solid #DEE3EA;
box-sizing: border-box;
border-radius: 3px;
height: 33px;
}
.inputQrCont >div input{
    
font-size: 10px;
line-height: 14px;
/* or 140% */

color: #7E96AE;
border: none;
width: calc(100% - 34px);
height: 100%;
}
/*----------footer-------------*/
.footerQrcont > div{
    display: flex;
    justify-content: center;
    align-items: center;
}
.footerQrcont p{
    margin: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */
    
    display: flex;
    align-items: center;
    
    color: #000000;
}
.footerQrcont .dwnlBtn{
    border: none;
    padding: 12px 20px;
    background: #106AD4;
    box-shadow: 2px 5px 20px 1px rgba(16, 106, 212, 0.2);
    border-radius: 6px;
    /*text*/
    
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */


    color: #FFFFFF;
}

/*----------------------------------qr--------------------------*/
.qrContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 250px;
    height: 375px;
    padding: 30px;
    background: #FF5757;
    box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.06), 0px 17.6944px 42.875px rgba(0, 0, 0, 0.06);
    
border-radius: 20px;
}
.qrContainer > h3 {

    font-weight: 700;
    font-size: 24px;
    line-height: 1.2;
    /* or 58% */
    width: 65%;
    
    color: #FFFFFF;
}
.qrContainer > p {

    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 13px;
    
    color: #FFFFFF;
}
.qrImg{
    background-color: white;
    padding: 10px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

}
.qrImg h3{
    font-weight: 700;
    font-size: 14.8px;
    line-height: 14px;
    /* or 95% */
    margin-top: 10px;
    
    color: #000000;

}
.logoTripQr{
    width: 25px;
    height: 30px;
    position: absolute;
    bottom: 17px;
    right: 20px;
}
/*----------------------------------Bigqr--------------------------*/
.bigQrContainer {
    margin: 20px;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-evenly;
    width: 500px;
    height: 800px;
    padding: 70px;
    background: #FF5757;
    box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.06), 0px 17.6944px 42.875px rgba(0, 0, 0, 0.06);
    
border-radius: 20px;
}
.bigQrContainer > h3 {

    font-weight: 700;
    width: 65%;
    font-size: 55px;
    line-height: 1.2;
    
    display: flex;
    align-items: center;
    
    color: #FFFFFF;
}
.bigQrImg{
    margin: 0 auto;
    background-color: white;
    width: 320px;
    height: 400px;
    padding: 10px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

}
.bigQrImg h3{
    margin: auto;
    font-weight: 700;
    font-size: 30px;
    line-height: 14px;
    /* or 35% */
    
    display: flex;
    align-items: center;
    text-align: center;
    
    color: #000000;

}
.bigLogoTripQr{
    width: 45px;
    height: 60px;
    position: absolute;
    bottom: 31px;
    right: 32px;
}
/*
.qrExplication {
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.qrExplication >h3{
    width: 100%;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 20px;
    line-height: 24px;
    /* identical to box height, or 120% /

    display: flex;
    align-items: center;

    color: #00264D;
}
.qrExplication >p{
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    /* or 150% /
    
    
    color: #00264D;
}
.qrExplication >p > span{
    color: #106AD4;
}
.qrExplication >button{
    border: none;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    width: 200px;
    height: 53px;
    
    background: #FF5757;
    box-shadow: 2px 3px 16px -3px rgba(0, 38, 77, 0.2);
    border-radius: 7px;
}*/