.popUpAgency {
  background-color: white;
  min-width: 300px !important;
  z-index: 5000;
  background-color: white;
  width: 100vw;
  height: 100vh;
  padding: 30px;
  overflow-y: scroll;
  box-shadow: 2px 7px 30px 5px rgba(0, 38, 77, 0.2);
  border-radius: 9px;
  animation: slideInUp; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 0.3s; /* don't forget to set a duration! */
}
.landingBusinessCont {
  display: flex;
  z-index: 7;
  width: calc(70%);
  height: calc(100vh - 155px);
  transform: rotateY(0)
}



@media (min-width: 768px) {
  .landingBusinessCont>div {
  }

  .landingBusinessCont {
    margin-top: 0
  }

}

.imagenBackBusiness,
.backImagenBackBusiness {
  background-image: url(../../img/landignFlight.avif);
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat
}

.backImagenBackBusiness {
  position: absolute;
  top: 10%;
  right: -20%;
  width: 110vw;
  height: 100vh;
  min-width: 350px;
  min-height: 350px;
  max-width: 500px;
  max-height: 500px;
  border-radius: 100%;
  z-index: -1
}

.linetitle {
  position: absolute;
  z-index: -10;
  top: 55%;
  left: 0;
  right: 0
}

.titlelandingBusiness {
  margin-top: 55px;
  font-weight: 900;
  width: 60%;
  min-width: 240px;
  text-shadow: 5px 6px 16px rgba(0, 0, 0, 0.25);
  font-size: 40px;
  line-height: 60px;
  color: #2B2C34
}

.subtitlelandingBusiness {
  height: min-content;
  border-radius: 10px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-color: #EFF0F3;
  font-weight: 400;
  font-size: 15px;
  line-height: 30px;
  margin-bottom: 80px;
  color: #000;
  text-shadow: 4px 5px 10px rgba(0, 0, 0, 0.25)
}

.subtitlelandingBusiness>p {
  height: min-content;
  border-left: 3px solid #FF5757;
  margin: 10px;
  padding-left: 9px;
  font-weight: 400;
  font-size: 15px;
  line-height: 30px;
  color: #000;
  text-shadow: 4px 5px 10px rgba(0, 0, 0, 0.25)
}

@media only screen and (min-width: 768px) {
  .landingBusinessCont {
    height:71vh
  }

  .backImagenBackBusiness {
    display: none
  }

  .imagenBackBusiness {
    border-radius: 270.5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    width: 100%;
    height: 100%;
    right: -52px;
    top: -55px
  }
}

@media (min-width: 576px) {
  .titlelandingBusiness {
    font-size: 50px;
    margin-bottom: 40px
  }

  .subtitlelandingBusiness {
    font-size: 14px
  }
}

@media (min-width: 768px) {
  .titlelandingBusiness {
    margin-top: 0;
    text-shadow: none;
    width: 100%;
    text-align: left;
    font-size: 46px;
    margin-bottom: 24px;
    font-weight: 700;
    line-height: 70px;
    color: #2B2C34
  }

  .subtitlelandingBusiness {
    text-shadow: none;
    width: 100%;
    background-color: transparent;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #9A9494;
    text-align: left
  }
}


@media (min-width: 992px) {
  .titlelandingBusiness {
    font-size: 50px
  }

  .subtitlelandingBusiness {
    font-size: 15px;
    margin-bottom: 35px
  }
}


@media (min-width: 1300px) {
  .titlelandingBusiness {
    font-size: 64px;
    margin-bottom: 24px;
    font-size: 64px;
    line-height: 96px
  }

  .subtitlelandingBusiness {
    font-size: 18px;
    margin-bottom: 30px
  }
}


.searchFlightCont {
  border-radius: 8px;
  height: 44px
}

.searchFlightCont input {
  box-shadow: 2px 3px 15px rgba(0, 0, 0, 0.25);
  font-size: 12px;
  height: 100%;
  width: 100%;
  background: #FFFFFE;
  border-radius: 8px;
  border: 1px solid #00264d;
  padding: 10px;
  font-weight: 400;
  color: #000
}

.searchFlightCont input::placeholder {
  color: silver
}

.searchFlightCont button {
  box-shadow: 3px 4px 16px rgba(0, 0, 0, 0.25);
  height: 100%;
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
  background: #FF5757;
  color: #fff;
  border-radius: 8px;
  border: none
}

.searchFlightCont button:hover {
  background: #fb6868
}

.searchFlightCont button:disabled {
  opacity: .5
}

.searchFlightCont>* {
  height: 100%
}

.fligthsHeader {
  background-color: #EFF0F3;
  overflow: scroll;
  min-height: 85px
}

.searchMinWidth {
  min-width: 325px
}

@media (min-width: 576px) {

  .searchFlightCont input,
  .searchFlightCont button {
    font-size: 15px
  }
}

@media (min-width: 768px) {
  .searchFlightCont {
    height: 60px
  }

  .searchFlightCont input,
  .searchFlightCont button {
    font-size: 15px;
    box-shadow: none
  }

  .searchFlightCont button {
    box-shadow: 3px 4px 16px rgba(0, 0, 0, 0.25);
    height: 100%;
    width: 100%;
    font-weight: 500;
    text-transform: capitalize;
    background: #FF5757;
    color: #fff;
    border-radius: 8px;
    border: none
  }

  .searchFlightCont input {
    padding: 25px
  }

}


@media (min-width: 992px) {
  .searchFlightCont button {
    font-size: 18px
  }

  .searchFlightCont {
    height: 72px
  }
}