.searchBodyScroll {
  position: relative;
  scroll-behavior: smooth;
  height: 100%;
  width: 100%
}

.mapRoute {
  display: none;
  width: 100%;
  min-width: 320px;
  position: relative;
  background-color: #edf0f4
}

@media (min-width: 860px) {
  .mapRoute {
    display: block
  }
}

.mapRouteMobile {
  display: block;
  width: 100%;
  min-height: 500px;
  position: sticky;
  top: 0;
  background-color: #edf0f4
}

@media (min-width: 860px) {
  .mapRouteMobile {
    display: none
  }
}


@media (max-width: 768px) {
  .searchBodyScroll {
    max-width: none !important;
    background-color: #fff;
    border-radius: 15px 15px 0 0;
    padding-top: 25px;
    width: 100%
  }

}

.searchBodyScroll .infinite-scroll-component__outerdiv {
  height: 100%
}

.searchBodyCont {
  max-height: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  justify-content: start;
  align-items: flex-start;
}

.loadingPlacesScreen {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
  background-color: #edf0f4a0;
  display: flex;
  justify-content: center;
  align-items: center
}


@media (min-width: 768px) {
  .loadingPlacesScreen {
    position: absolute
  }
}

.noPlaces {
  padding: 0 15px;
  justify-content: center;
  align-items: center
}

.loadingPlacesScreen h3,
.noPlaces h3 {
  color: black;
  font-size: 18px;
  font-weight: 600
}

::-webkit-scrollbar {
  width: 0;
  background: transparent
}

.optionsPlacesCalScroll {
  overflow-y: scroll;
  justify-content: space-evenly;
  height: calc(100vh - 163px)
}

@media only screen and (min-width: 768px) {
  .optionsPlacesCalScroll {
    padding: 0 5px;
    height: calc(100vh - 180px)
  }
  
  .iframe-calendar .optionsPlacesCalScroll {
    height: calc(100vh - 110px) !important;
  }
}

.btnInfo {
  margin: 0
}

.lineGrayExpandList {
  content: '';
  display: block;
  background-color: #D2D2D2;
  height: 5px;
  width: 36px;
  position: absolute;
  border-radius: 30px;
  border: none;
  top: 16px;
  right: calc(50% - 18px)
}

.showMapMobilebtn {
  position: absolute;
  cursor: pointer;
  width: 150px;
  right: calc(50% - 75px);
  bottom: 40px;
  border: none;
  z-index: 3;
  color: #fff;
  background: #222;
  box-shadow: 3px 4px 16px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
  font-weight: 600;
  font-size: 9px;
  line-height: 15px
}


.titleSearchPageCont {
  margin-top: 20px;
  font-weight: 700;
  width: 80%;
  font-size: 20px;
  line-height: 24px;
  max-height: 100px;
  color: #00264d
}

.titleSearchPageCont h2 {
  margin: 0;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  padding-bottom: 25px;
  text-align: center;
  color: #000
}

.titleSearchPageCont p {
  margin: 0;
  font-weight: 400;
  width: 100%;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 24px;
  color: #00264d
}


@media (min-width: 768px) {
  .titleSearchPageCont {
    margin-top: 20px;
    font-weight: 700;
    width: 80%;
    font-size: 20px;
    line-height: 24px;
    color: #00264d
  }

  .titleSearchPageCont h2 {
    margin: 0;
    font-weight: 700;
    margin-bottom: 32px;
    margin-right: 60px;
    font-size: 20px;
    line-height: 24px;
    text-align: left;
    color: #00264d
  }
}