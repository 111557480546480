input[type="password"] {
  font-family: caption;
  font-size: 20px
}

.swal2-popup .swal2-styled:focus {
  box-shadow: none !important
}

.popUpLogSignCont {
  z-index: 5000;
  border-radius: 9px;
  padding: 15px;
  min-width: 339px;
  width: 100%;
  background-color: #fff
}

.popUpForceWidth {
  max-width: none
}

@media (min-width: 768px) {
  .popUpLogSignCont {
    width: auto;
    box-shadow: 2px 7px 25px 5px rgba(0, 38, 77, 0.2);
    animation: zoomIn;
    animation-duration: .5s
  }

  .popUpForceWidth {
    max-width: 420px
  }
}

.headerPopUp h2 {
  font-size: 20px;
  margin-bottom: 4px;
  font-weight: 700;
  color: #00264d
}

.headerPopUp p {
  font-size: 14px;
  font-weight: 400;
  color: #00264d;
  margin-bottom: 0
}

.headerPopUp span {
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  color: #106ad4
}

.labelLogSign {
  margin-top: 19px;
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 10px;
  display: block;
  color: #00264d
}

.inputLogSign {
  border: none;
  box-sizing: border-box;
  border-radius: 0;
  width: 100%;
  height: 24px;
  font-weight: 400;
  font-size: 10px
}

.inputLogSignCont {
  border: .6px solid #dee3ea !important;
  border-radius: 3px
}

.inputLogSign::placeholder {
  color: #7e96ae;
  font-size: 10px;
  font-weight: 300
}

.borderRadius3 {
  border-radius: 3px
}

.iconInputLogSign {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  display: flex;
  height: 24.4px;
  font-size: 10px;
  color: #7e96ae;
  align-items: center;
  justify-content: center
}

.checkboxLogSign {
  border: 1px solid #dee3ea !important;
  box-sizing: border-box;
  border-radius: 1px
}

.labelChekLogSign {
  margin: 0;
  display: flex;
  height: 100%;
  align-items: center;
  font-size: 9px;
  font-weight: 400;
  color: #7e96ae
}

.errormsg {
  font-size: 8px;
  font-weight: 500;
  color: red
}

.labelChekLogSign span {
  font-weight: 500;
  font-size: 9px;
  text-transform: capitalize;
  color: #106ad4
}

.forgetPasswordBtn {
  background-color: transparent;
  border: none;
  color: #106ad4;
  font-size: 8px;
  padding: 0
}

.btnCancelLog {
  background-color: transparent;
  border: none;
  color: #106ad4;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  transition: all .3s ease-in
}

.btnCancelLog:hover {
  background-color: #7e96ae23 !important
}