.landingCont {
  display: flex;
  flex-direction: column;
  z-index: 7;
  height: 60vh;
  width: calc(100%);
  margin-left: 80px;
  margin-top: 10%;
  transform: rotateY(0)
}

@media (min-width: 768px) {
  .landingCont {
    margin-top: 8%
  }
}

.imagen1,
.imagen2,
.imagen3,
.imagen4 {
  display: none;
  z-index: -1;
  position: absolute;
  box-shadow: 4px 11px 35px 10px rgba(16, 106, 212, 0.12);
  border-radius: 8px;
  height: auto
}

.imagen1 {
  width: 300px;
  left: 58%;
  top: -120px
}

.imagen2 {
  width: 240px;
  right: 6%;
  top: 20px
}

.imagen3 {
  width: 220px;
  right: 25%;
  top: 50.95%
}

.imagen4 {
  width: 280px;
  right: 6%;
  top: 62%
}

@media only screen and (max-width: 765px) {
  .landingCont {
    width: calc(100%);
    margin-left: 0
  }
}

.linetitle {
  position: absolute;

  z-index: -10;
  top: 55%;
  left: 0;
  right: 0
}

.titleLanding {
  z-index: 1;
  font-size: 14vw;
  font-weight: 900;
  color: #00264d;
  text-align: center;
  margin-bottom: 30px;
  text-shadow: 2px 5px 10px rgba(0, 38, 77, 0.15)
}

.subtitleLanding {
  text-align: center;
  margin-bottom: 55px;
  max-width: 564px;
  font-weight: 300;
  font-size: 5vw;
  line-height: 24px;

  color: #00264d
}

@media only screen and (max-width: 780px) {
  .imagen1 {
    width: 160px;
    left: 10%;
    top: 240px
  }

  .imagen2 {
    width: 140px;
    right: 2%;
    top: 56%
  }

  .imagen3 {
    width: 130px;
    left: 15%;
    top: 90%
  }

  .imagen4 {
    width: 190px;
    right: 2%;
    top: 94%
  }

}


@media (min-width: 576px) {
  .titleLanding {
    font-size: 10vw;
    margin-bottom: 40px
  }

  .subtitleLanding {
    font-size: 20px;
    margin-bottom: 20px
  }
}


@media (min-width: 768px) {
  .titleLanding {
    text-align: left;
    font-size: 46px;
    margin-bottom: 36px
  }

  .subtitleLanding {
    text-align: left;
    max-width: 564px
  }

  .imagen1 {
    width: 240px;
    left: 58%;
    top: -90px
  }

  .imagen2 {
    width: 190px;
    right: 6%;
    top: 10px
  }

  .imagen3 {
    width: 180px;
    right: 24%;
    top: 51%
  }

  .imagen4 {
    width: 220px;
    right: 6%;
    top: 64%
  }

  .imagen1,
  .imagen2,
  .imagen3,
  .imagen4 {
    display: block
  }
}


@media (min-width: 992px) {
  .titleLanding {
    font-size: 55px
  }

  .subtitleLanding {
    font-size: 24px;
    margin-bottom: 35px
  }
}


@media (min-width: 1300px) {
  .titleLanding {
    font-size: 65px
  }

  .subtitleLanding {
    font-size: 24px;
    margin-bottom: 48px
  }
}
